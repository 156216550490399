export default {
  path: "/data",
  name: "data",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/data/reservoir_area",
  children: [
    {
      path: "reservoir_area",
      name: "reservoir_area",
      component: () => import("@/views/data/reservoirArea/index"),
    },
    {
      path: "product",
      name: "product",
      component: () => import("@/views/data/product/index"),
    },
  ],
};
