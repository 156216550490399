import functions from "@/utils/functions";
import "ant-design-vue/dist/antd.less";
import Antd from "ant-design-vue/es";
import VueRouter from "vue-router";
import router from "./router";
import App from "./App.vue";
import Vue from "vue";

Vue.config.productionTip = false;
Vue.prototype.$functions = functions;
Vue.use(VueRouter);
Vue.use(Antd);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
